import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import PortfolioSlider from "./PortfolioSlider/";
import PortfolioSlide from "./PortfolioSlider/PortfolioSlide";

const PortfolioWrap = styled.div`
  margin: 0 auto;
  // padding-top: 60px;
  height: auto;
  opacity: 1;
  // transition: height 500ms 500ms, opacity 500ms 0ms;

  &.inactive {
    // display: none;
    height: 0;
    opacity: 0;
  }
`;

// const Portfolio = (props) => {
class Portfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewclass: "inactive",
    };
  }

  changeView = (showit) => {
    const newview = showit ? "active" : "inactive";
    // console.log(showit + " :: " + newview);
    this.setState({
      viewclass: newview,
    });
  };

  render() {
    return (
      <PortfolioWrap className={this.state.viewclass}>
        <PortfolioSlider className="marty">
          <PortfolioSlide
            title="NYPL Reservoir Design System"
            skills={[
              "Accessibility",
              "Documentation",
              "React",
              "Storybook",
              "Typscript",
              "UX",
              "Visual design",
            ]}
            description="The Reservoir Design System is the base for the design and development of the NYPL web properties. As the sole design technologiest at NYPL, I organized and contributed to all aspects of the design system, including product planning, UX and visual design, development, and writing detailed documentation. I worked closely with designers, developers, and stakeholders across the organization to evagelize the design system and work toward greater adoption and improve ROI."
            image="portfolio-reservoir.jpg"
            link="https://nypl.github.io/nypl-design-system/reservoir/v3"
            repo="https://github.com/NYPL/nypl-design-system"
          />

          <PortfolioSlide
            title="WES Stratergic Plan"
            skills={[
              "HTML",
              "CSS/SCSS",
              "Javascript",
              "Responsive",
              "WordPress",
            ]}
            description="The website for the Washington Episcopal School Strategic Plan was built using the WordPress CMS. The site design is non-traditional and required meticulous implementation of enhanced page builder tools. Custom parallax coding was used to create movement and playfulness throughout the site."
            image="portfolio-wes.jpg"
            link="https://wesstrategicplan.com"
          />

          <PortfolioSlide
            title="Binghamton Arts &amp; Athletics Community Fund"
            skills={[
              "Branding",
              "CSS/SCSS",
              "HTML",
              "Logo design",
              "Javascript",
              "SEO",
              "Social media",
              "Website design",
              "WordPress",
            ]}
            description="The Binghamton Arts & Athletics Community Fund needed to move fast to establish brand identity and get an online presence in the form of a website and social media up and running. I worked closely with the BAACF Board members to iterate over logo and branding concepts, finalizing the logo and overall brand and publishing a WordPress driven website within eights weeks."
            image="portfolio-baacf.jpg"
            link="https://baacf.com"
          />

          <PortfolioSlide
            title="Dreitonal"
            skills={["CSS/SCSS", "HTML", "Javascript", "WordPress"]}
            description="The Dreitonal project required a WordPress multilingual website. To simplify site maintenance, the menu system was enhanced to dynamically create links to the corresponding multilingual pages, allowing site content creators to focus on the content without having to spend extra time maintaining the site navigation."
            image="portfolio-dreitonal.jpg"
            link="https://dreitonal.at"
          />

          <PortfolioSlide
            title="Abortion Defense Network"
            skills={[
              "CSS/SCSS",
              "Formstack",
              "HTML",
              "Javascript",
              "WordPress",
            ]}
            description="The Abortion Defense Network site is built atop the WordPress CMS and incorporates tight intergration with Formstack, a third party form builder and data management system."
            image="portfolio-adn.jpg"
            link="https://abortiondefensenetwork.org"
          />

          <PortfolioSlide
            title="Newark Safety"
            skills={["Bonterra", "CSS/SCSS", "HTML", "Javascript", "WordPress"]}
            description="The Newark Safety site is built using the WordPress CMS and incorporates tight intergration with Bonterra, a third party software package focused on data collection and social media engagement for nonprofit organizations."
            image="portfolio-newark.jpg"
            link="https://newarksafety.org"
          />

          {/* <PortfolioSlide
            title="Logo design and branding"
            skills={["Graphic design"]}
            description="The eLearning Player is a single page application developed as the primary platform for delivering the core course products for SmartPros, A Kaplan Company.  The original coursware was developed in Adobe Flash and eventually converted to full native HTML application.  The platform offers varying course styles with multiple learning components available and supports thousands of course titles."
            image="portfolio-elp.jpg"
            // link=""
          /> */}

          <PortfolioSlide
            title="eLearning Player"
            skills={[
              "CSS",
              "Graphic design",
              "HTML",
              "Javascript",
              "jQuery",
              "SCORM",
              "Streaming video",
            ]}
            description="The eLearning Player is a single page application developed as the primary courseware to delive the core education products for SmartPros, A Kaplan Company. The original application was developed using Adobe Flash and eventually converted to a full native HTML application. The application offers varying course styles with multiple learning components and supports thousands of course titles."
            image="portfolio-elp.jpg"
            link=""
          />

          <PortfolioSlide
            title="AGW Events"
            skills={[
              "CSS",
              "Database design",
              "HTML",
              "Javascript",
              "PHP",
              "Responsive",
            ]}
            description={
              "The AGW platform is a scalable event registration portal. Each event has a dedicated custom website with restricted access. Site administrators can utilize backend utilities to generate reports about each event's registrants."
            }
            image="portfolio-agw.jpg"
          />

          <PortfolioSlide
            title="SmartPros eCampus"
            skills={["Graphic design"]}
            description={
              "Mockup for desktop and mobile showing a recommended layout for the SmartPros eCampus homepage."
            }
            image="portfolio-ecampus-redesign-2.jpg"
          />

          <PortfolioSlide
            title="Subscription packages mockups"
            skills={["Graphic design"]}
            description={
              "Mockup showing a recommended layout for a marketing landing page."
            }
            image="portfolio-packages.jpg"
          />

          <PortfolioSlide
            title="The Gardiner Gazette"
            skills={[
              "CSS/SCSS",
              "Graphic design",
              "HTML",
              "Javascript",
              "Logo design",
              "WordPress",
            ]}
            description={
              "The WordPress CMS was used to develop a mechanism for delivering The Gardiner Gazette's quarterly newsletter publication.  In addition to the site development, I also handled website graphic design, logo design and branding."
            }
            link="https://gardinergazette.com"
            image="portfolio-gardiner-gazette.jpg"
          />

          <PortfolioSlide
            title="The Count, A Musical"
            skills={[
              "Logo design",
              "Photography",
              "Print design",
              "Social media",
            ]}
            description={
              "Logo design, print materials and event photography for The Count, A Musical."
            }
            image="portfolio-the-count.jpg"
          />
        </PortfolioSlider>
      </PortfolioWrap>
    );
  }
}

export default Portfolio;
